@font-face {
    font-family: 'Ubuntu';
    font-style: normal;
    font-weight: 400;
    src: url(https://fonts.gstatic.com/s/ubuntu/v15/4iCs6KVjbNBYlgoKfw72nU6AFw.woff2) format('woff2');
}

@font-face {
    font-family: 'Ubuntu';
    font-style: normal;
    font-weight: 700;
    src: url(https://fonts.gstatic.com/s/ubuntu/v15/4iCv6KVjbNBYlgoCxCvjsGyNPYZvgw.woff2) format('woff2');
}