@use '../../styles/partials/variables' as *;
@use '../../styles/partials/mixins' as *;

.books-by-genre-page {
    border: 2px solid #212e6a;
    margin: 0;
    padding: 0 0 $padding*2;
    display: flex;
    flex-direction: column;
    align-items: center;
    text-align: center;
    min-height: 100vh;
}

.book__container {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    text-align: center;
}