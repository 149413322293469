@use '../../styles/partials/colors';
@use '../../styles/partials/variables' as *;
@use '../../styles/partials/mixins' as *;


.genre {
    margin: $margin*2;

    &__container {
        display: flex;
        flex-wrap: wrap;
        justify-content: center;
        text-align: center;
    }

    
    &__book-container {
        // background: white;
        // border: black solid 2px;
        position: relative;
        margin-bottom: 60px;
        
        @include tablet {
            max-width: calc(50% - #{$margin}*4);

        }
    }
    
    &__book-cover {
        min-width: 30%;
        max-width: 50%;
    }

    &__title {
        margin: 0;
        position: absolute;
        top: 42%;
        left: 49%;
        color: colors.$white;
        transform: translate(-50%, -50%) rotateZ(-18deg) skew(18deg, 3deg);
        text-align: center;
        max-width: 100px;
        font-size: 100%;

        @include tablet {
            font-size: 140%;
            top: 41%;
            left: 49%;
        }
    }

    &__description {
        margin-bottom: 0
    }
}