@use '../../styles/partials/variables' as *;
@use '../../styles/partials/mixins' as *;

.genre-page {
    border: 2px solid #212e6a;
    margin: 0;
    padding: 0 0 $padding*2;
    display: flex;
    flex-direction: column;
    align-items: center;
    text-align: center;
}